import React, { useContext } from "react"
import { GlobalDataContext } from "../context/context"
import TransparentHeader from "../components/global/TransparentHeader"
import BaseLayout from "../components/global/BaseLayout"
import BlockContentPricipal from "../components/block/BlockContent_3"
import ValuesContent from "../components/values/Values_1"
import CallToAction2 from '../components/callToAction/CallToAction_2'



function About() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName="About">
      <div className="md:max-w-full w-full">
        <TransparentHeader
          headertitle="About"
          Subheader="About"
          bgimg={`${rpdata?.stock?.[9]}`}
        />

        <BlockContentPricipal
          title={'a little about us'}
          texts={rpdata?.dbAbout?.[0]?.text}
          listAbout
          bgImg1={rpdata?.stock?.[2]}
          bgImg2={rpdata?.stock?.[1]}
        />

        <ValuesContent
          mission={rpdata?.dbValues?.[0].description}
          vision={rpdata?.dbValues?.[1].description}
          whychoose={rpdata?.dbValues?.[2].description}
          img1={rpdata?.stock?.[6]}
          img2={rpdata?.stock?.[8]}
          img3={rpdata?.stock?.[7]}
        />

        <div className="mb-20">
          <CallToAction2
            title={rpdata?.dbSlogan?.[4]?.slogan}
            subtitle={rpdata?.dbSlogan?.[0]?.slogan}
            phone={rpdata?.dbPrincipal?.phones?.[0]?.phone}
            bgImages={rpdata?.stock?.[5]}
            texts={rpdata?.dbAbout?.[1]?.text}
          />
        </div>

      </div>
    </BaseLayout>
  );
}

export default About;
